import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle} page="fourohfour">
      <div className="container">
        <SEO title="404: Not Found" />
        <h1 className="center">404</h1>
        <p className="center">There’s nothing here, unfortunately. Try going <a href="/">home</a>.</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
